import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectVendorOfferDetailScreen from './selector';
import reducer from './reducer';
import saga from './saga';

import {
  AspectRatio,
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Text,
  Image,
  Pressable,
  Divider, 
  Flex,
  Stack,
  Fab,
  ScrollView, 
  Button} from 'native-base';
import {FaCalendarAlt, FaBuilding, FaArrowRight, FaHotel} from 'react-icons/fa';
import moment from 'moment';
import { ContainerPage } from '../../components/ContainerPage';
import ImageSlider from '../../components/ImageSlider';
import TaskDetailPreloader from '../../components/TaskDetailPreloader';
import MyAlert from '../../components/MyAlert';
import { commonStyle } from '../../styles';
import { getOfferDetail, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';
import history from '../../util/history';

export function VendorOfferDetailScreen({
  dispatch,
  location,
  navigation,
  vendorOfferDetailScreen,
  onUpdateInput,
  onGetResult,
}){
  const {id_offer, readOnly} = location.state;
  const[userData, setUserData] = useState();
  const imageList = [
    {path: '../../images/pintu.jpg'},
    {path: '../../images/pintu.jpg'},
    {path: '../../images/pintu.jpg'},
  ];
  
  useEffect(() => {
    console.log("readonly:", readOnly);
    onGetResult(id_offer);
  },[]);

  const handleGetData = () => {
    onGetResult(id_offer);
  }

  return (
        <ContainerPage options={{statusBar: true, prevPage: 'vendor-offer', title: 'Penawaran Vendor'}}>
          <Flex flex={1}>
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
              {vendorOfferDetailScreen.loading && <TaskDetailPreloader/> }
              {!vendorOfferDetailScreen.loading && 
              <Flex mt={3}>
                {!vendorOfferDetailScreen.results && <MyAlert status="info" title="Maaf, Penawaran belum ditemukan."/>}
                {vendorOfferDetailScreen.results &&
                <VStack space={7} mt={2} mb={40} mx={3}>
                  <Box p={5} mt={3} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                      <VStack>
                        <Center>
                          <Avatar size="lg" bg="primary.900" mb={4}>
                              {helper.getInitials(vendorOfferDetailScreen.results.nama_petugas)}
                          </Avatar>
                        </Center>
                        <Center>
                          <Text color="darkText" isTruncated maxWidth="90%" fontSize="md" fontWeight="bold">{vendorOfferDetailScreen.results.nama_petugas}</Text>
                        </Center>
                        <Center>
                          <Text color="muted.400" isTruncated maxWidth="90%" fontSize="xs" fontWeight="bold">{vendorOfferDetailScreen.results.hp_petugas}</Text>
                        </Center>
                        <VStack space={2} flex={1} mt={3}>
                          <Box>
                            <VStack>
                              <Text fontSize="xs" color="muted.600">No Penawaran</Text>
                              <Text color="darkText" isTruncated bold>{vendorOfferDetailScreen.results.code_penawaran_request}</Text>
                            </VStack>
                          </Box>
                          <Box>
                            <VStack>
                              <Text fontSize="xs" color="muted.600">Deadline Penawaran</Text>
                              <Text color="darkText" bold>{moment(vendorOfferDetailScreen.results.deadline_penawaran_request, "YYYY-MM-DD HH:mm:ss").format("DD MMMM YYYY")}</Text>
                            </VStack>
                          </Box>
                          <Box>
                            <VStack>
                              <Text fontSize="xs" color="muted.600">Status Penawaran</Text>
                              <Text color="darkText" bold>{!vendorOfferDetailScreen.results.status_penawaran_text ? '-' : vendorOfferDetailScreen.results.status_penawaran_text}</Text>
                            </VStack>
                          </Box>
                          <Box>
                            <VStack>
                              <Text fontSize="xs" color="muted.600">Nominal Penawaran</Text>
                              <Text color="darkText" bold>{!vendorOfferDetailScreen.results.nominal_penawaran ? '-' : `Rp.${vendorOfferDetailScreen.results.nominal_penawaran.toLocaleString()}`}</Text>
                            </VStack>
                          </Box>
                          <Box>
                            <VStack>
                              <Text fontSize="xs" color="muted.600">Catatan</Text>
                              <Text color="darkText" bold>{!vendorOfferDetailScreen.results.note_penawaran_request ? '-' : `${vendorOfferDetailScreen.results.note_penawaran_request}`}</Text>
                            </VStack>
                          </Box>
                        </VStack>
                      </VStack>
                  </Box>
                  {vendorOfferDetailScreen.results.list_detail.length == 0 && <MyAlert status="info" title="Maaf, Detail Penawaran belum ditemukan."/>}
                  {vendorOfferDetailScreen.results.list_detail.length > 0 && vendorOfferDetailScreen.results.list_detail.map((row, idx) => (
                  <Box key={idx} p={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                      <VStack>
                        <Flex mb="2.5">
                          <ImageSlider images={row.attachments}/>
                        </Flex>
                        <Box mb={2}>
                          <Text fontWeight="bold">{row.nama_inventaris}</Text>
                        </Box>
                        <HStack flex={1} mb={1}>
                          <Stack flex={1} direction="row" justifyContent="space-between" alignItems="center">
                            <Box>
                              <Icon color="muted.400" as={<Box><FaCalendarAlt/></Box>} size={3}></Icon>
                            </Box>
                            <Box flex={1} ml={1}>
                              <Text color="muted.400" fontWeight={500} fontSize="xs">{moment(vendorOfferDetailScreen.results.date_penawaran_request, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY HH:mm")}</Text>
                            </Box>
                          </Stack>
                          <Stack flex={1} direction="row" justifyContent="space-between" alignItems="center">
                            <Box>
                              <Icon color="muted.400" as={<Box><FaBuilding/></Box>} size={3}></Icon>
                            </Box>
                            <Box flex={1} ml={1}>
                              <Text color="muted.400" fontWeight={500} fontSize="xs">{row.nama_gedung}</Text>
                            </Box>
                          </Stack>
                        </HStack>
                        <HStack flex={1} mb={2}>
                          <Stack flex={1} direction="row" justifyContent="space-between" alignItems="center">
                            <Box>
                              <Icon color="muted.400" as={<Box><FaHotel/></Box>} size={3}></Icon>
                            </Box>
                            <Box flex={1} ml={1}>
                              <Text color="muted.400" fontWeight={500} fontSize="xs">{row.nama_ruang}</Text>
                            </Box>
                          </Stack>
                        </HStack>
                        <Box mb={2}>
                          <Text color="darkText" textAlign="justify">
                            {row.deskripsi}
                            {/* {`Kode Inventaris: ${row.code_inventaris} \nRuang: ${row.nama_ruang} \nLantai: ${row.lantai_ruang}`} */}
                          </Text>
                        </Box>
                      </VStack>
                  </Box>
                  ))}
                </VStack>
                }
              </Flex>
              }
            </ScrollView>
          </Flex>
          {vendorOfferDetailScreen.results && <Fab onPress={() => history.push('vendor-offer-form', {vendor_offer: vendorOfferDetailScreen.results, readOnly: readOnly})} renderInPortal={false} shadow={2} size="lg" icon={<Icon color="white" as={<Box><FaArrowRight/></Box>} size="lg" />} />}
        </ContainerPage>
      );
    
}

const styles = {
  errorTextStyle: {
    color: 'red',
    fontStyle: 'italic',
    fontWeight: 'bold',
    alignSelf: 'center'
  }
};

const mapStateToProps = createStructuredSelector({
  vendorOfferDetailScreen: makeSelectVendorOfferDetailScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onGetResult: (id_offer) => dispatch(getOfferDetail(id_offer)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(VendorOfferDetailScreen);